<template>
  <div>
    <el-row :gutter="20" class="mb-6">
      <el-col :span="12" class="mb-5">
        <span>Индивидуальные предпочтения</span>
      </el-col>
      <el-col :span="12" class="is-flex is-justify-content-flex-end mb-3">
        <el-button type="primary" icon="el-icon-plus" @click="openDialogCreate">
          Добавить исключения и замены
        </el-button>
      </el-col>
      <el-col :span="24">
        <el-table :data="props.excluded">
          <el-table-column prop="ingredient" label="Исключение" />
          <el-table-column prop="replaceIngredient" label="Замена" />
          <el-table-column prop="description" label="Примечание" />
          <el-table-column align="right">
            <template slot-scope="{ row }">
              <el-button
                icon="el-icon-edit"
                type="primary"
                size="mini"
                @click="openDialogUpdate(row)"
              />
              <el-button
                icon="el-icon-delete"
                type="danger"
                size="mini"
                @click="deleteIngredient(row.id)"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <replace-ingredient-create ref="replaceIngredientCreateRef" />
    <replace-ingredient-update ref="replaceIngredientUpdateRef" />
  </div>
</template>

<script>
import store from '@/app/app-state';
import {
  INGREDIENTS_STORE_MODULE_NAME,
  ingredientsStoreModule,
} from '@/app/administration/ingredients/ingredientsStoreModule';
import ReplaceIngredientCreate from '@/views/clients-base/client/components/replace-ingredient-create/replace-ingredient-create';
import ReplaceIngredientUpdate from '@/views/clients-base/client/components/replace-ingredient-update/replace-ingredient-update';
import { useReplaceIngredient } from './useReplaceIngredient';

export default {
  props: {
    excluded: {
      type: Array,
      default: () => [],
    },
  },
  components: { ReplaceIngredientUpdate, ReplaceIngredientCreate },
  setup(props) {
    if (!store.hasModule(INGREDIENTS_STORE_MODULE_NAME)) {
      store.registerModule(INGREDIENTS_STORE_MODULE_NAME, ingredientsStoreModule);
    }
    const {
      replaceIngredientCreateRef,
      openDialogCreate,
      deleteIngredient,
      replaceIngredientUpdateRef,
      openDialogUpdate,
    } = useReplaceIngredient();

    return {
      replaceIngredientCreateRef,
      openDialogCreate,
      props,
      deleteIngredient,
      replaceIngredientUpdateRef,
      openDialogUpdate,
    };
  },
};
</script>
