<template>
  <el-dialog title="Редактировать исключения и замены" :visible.sync="isOpen">
    <div class="mb-3">
      <label>Ингредиент-исключение</label>
      <el-select
        v-model="excludedIngredient"
        placeholder="Ингредиент-исключение"
        style="width: 100%"
        class="mt-3"
        filterable
        icon="el-icon-search"
      >
        <el-option
          v-for="item in ingredients"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div>
      <label>Ингредиент-замена</label>
      <el-select
        v-model="replaceIngredient"
        placeholder="Ингредиент-замена"
        class="mt-3"
        style="width: 100%"
        filterable
        icon="el-icon-search"
      >
        <el-option
          v-for="item in ingredients"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="mt-3">
      <label>Примечание</label>
      <el-input
        v-model="description"
        class="mt-3"
        type="textarea"
        :rows="2"
        placeholder="Примечание..."
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="storeReplaceIngredient">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { useReplaceIngredientUpdate } from './useReplaceIngredientUpdate';

export default {
  setup() {
    const {
      isOpen,
      open,
      excludedIngredient,
      replaceIngredient,
      ingredients,
      storeReplaceIngredient,
      description,
    } = useReplaceIngredientUpdate();
    return {
      isOpen,
      open,
      excludedIngredient,
      replaceIngredient,
      ingredients,
      storeReplaceIngredient,
      description,
    };
  },
};
</script>
