<template>
  <el-autocomplete
    v-model="model"
    style="width: 100%"
    :fetch-suggestions="querySearchAsync"
    :debounce="300"
    :placeholder="placeholder"
    :value-key="valueKey"
    @select="select"
  />
</template>

<script>
export default {
  name: 'Autocomplete',
  props: {
    action: {
      type: String,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Начните вводить',
    },
    values: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    value: 'title',
    valueKey: 'title',
  }),
  computed: {
    model: {
      get() {
        return this.values;
      },
      set(value) {
        this.$emit('update:values', value);
      },
    },
  },
  methods: {
    async querySearchAsync(queryString, cb) {
      let result = [];
      if (this.action === 'DIRECTORIES') {
        this.value = 'dish.title';
        const getter = 'AUTOCOMPLETE_DIRECTORIES';
        if (queryString) {
          this.$store.dispatch(`FETCH_${this.action}`, { filter: { [this.value]: queryString } });
        } else {
          this.$store.dispatch(`FETCH_${this.action}`, { page: 1 });
        }
        result = this.$store.getters[getter];
      } else if (this.action === 'CLIENTS') {
        this.value = 'name';
        this.valueKey = 'name';
        if (queryString) {
          this.$store.dispatch(`FETCH_${this.action}`, { filter: { [this.value]: queryString } });
        } else {
          this.$store.dispatch(`FETCH_${this.action}`, { page: 1 });
        }
        result = this.$store.getters[this.action].data;
      } else {
        if (queryString) {
          await this.$store.dispatch(`FETCH_${this.action}`, {
            filter: { [this.value]: queryString },
          });
        } else {
          await this.$store.dispatch(`FETCH_${this.action}`, { page: 1 });
        }
        result = this.$store.getters[this.action].data;
      }
      cb(result || []);
    },
  },
};
</script>
