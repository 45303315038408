import { computed, ref } from '@vue/composition-api';
import store from '@/app/app-state';
import { INGREDIENTS_STORE_MODULE_NAME } from '@/app/administration/ingredients/ingredientsStoreModule';
import { Notification } from 'element-ui';

export function useReplaceIngredientCreate() {
  const isOpen = ref(false);
  const excludedIngredient = ref(null);
  const replaceIngredient = ref(null);
  const description = ref(null);

  const beforeClose = () => {
    excludedIngredient.value = null;
    replaceIngredient.value = null;
    description.value = null;
    isOpen.value = false;
  };

  const fetchIngredients = () => {
    store.dispatch(`${INGREDIENTS_STORE_MODULE_NAME}/fetchIngredients`);
  };

  const open = () => {
    isOpen.value = true;
    fetchIngredients();
  };

  const ingredients = computed(
    () => store.getters[`${INGREDIENTS_STORE_MODULE_NAME}/autocompleteIngredients`]
  );

  const storeReplaceIngredient = async () => {
    try {
      await store.dispatch('STORE_EXCLUDED', {
        ingredient_id: excludedIngredient.value,
        replace_ingredient_id: replaceIngredient.value,
        description: description.value,
      });
      Notification({
        title: 'Успешно',
        message: 'Предпочтения успешно добавленно',
        duration: 1500,
        type: 'success',
      });
      isOpen.value = false;
      excludedIngredient.value = null;
      replaceIngredient.value = null;
      description.value = null;
    } catch (error) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка добавления',
        duration: 1500,
      });
    }
  };

  return {
    isOpen,
    open,
    ingredients,
    storeReplaceIngredient,
    excludedIngredient,
    replaceIngredient,
    description,
    beforeClose,
  };
}
