<template>
  <el-dialog :title="title" :visible.sync="visible" width="30%">
    <el-form label-position="top" :model="dataAddress">
      <el-form-item label="Область">
        <el-input v-model="dataAddress.region" placeholder="Самарская область" />
      </el-form-item>
      <el-form-item label="Город">
        <el-input v-model="dataAddress.city" placeholder="Щёлково" />
      </el-form-item>
      <el-form-item label="Район">
        <el-select
          v-model="dataAddress.districtName"
          filterable
          placeholder="Район"
          @change="onChange"
        >
          <el-option
            v-for="item in districts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Улица">
        <el-input v-model="dataAddress.street" placeholder=" пл. Ленина" />
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Дом">
            <el-input v-model="dataAddress.house" placeholder="95" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Подъезд">
            <el-input v-model="dataAddress.entrance" placeholder="2" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Квартира">
            <el-input v-model="dataAddress.apartment" placeholder="63" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Комментарий">
            <el-input
              v-model="dataAddress.comment"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Комментарий"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-checkbox v-model="dataAddress.isMain">Предпочтительный адрес</el-checkbox>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="sendData"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import store from '@/app/app-state';
import { computed } from '@vue/composition-api';
import {
  DISTRICT_STORE_MODULE_NAME,
  districtsStoreModule,
} from '@/app/settings/districts/districtsStoreModule';

export default {
  name: 'AddressModal',
  props: {
    title: {
      type: String,
      default: 'Добавить адрес',
    },
    show: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    defaultAddress: {
      id: '',
      region: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      entrance: '',
    },
  }),
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    dataAddress: {
      get() {
        return this.address ? this.address : this.defaultAddress;
      },
      set(value) {
        this.$emit('update:address', value);
      },
    },
  },
  methods: {
    async sendData() {
      this.loading = true;
      try {
        if (this.dataAddress.id) {
          await this.$store.dispatch('UPDATE_ADDRESS', this.dataAddress);
        } else {
          await this.$store.dispatch('STORE_ADDRESS', this.dataAddress);
        }
        this.$notify({
          title: 'Успешно',
          message: 'Адрес успешно добавлен',
          duration: 1500,
          type: 'success',
        });
        this.visible = false;
      } catch (error) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка добавления',
          duration: 1500,
        });
      }
      this.loading = false;
    },
    onChange(id) {
      this.dataAddress.districtName = this.districts.find((d) => d.value === id).label;
      this.dataAddress.district = id;
    },
  },
  setup() {
    if (!store.hasModule(DISTRICT_STORE_MODULE_NAME)) {
      store.registerModule(DISTRICT_STORE_MODULE_NAME, districtsStoreModule);
    }
    const fetchDistrict = (params) => {
      store.dispatch(`${DISTRICT_STORE_MODULE_NAME}/fetchDistricts`, params);
    };
    fetchDistrict();
    const districts = computed(
      () => store.getters[`${DISTRICT_STORE_MODULE_NAME}/autocompleteDistricts`]
    );
    return { districts };
  },
};
</script>
