import { ref } from '@vue/composition-api';
import store from '@/app/app-state';

export function useReplaceIngredient() {
  const replaceIngredientCreateRef = ref(null);
  const replaceIngredientUpdateRef = ref(null);

  const openDialogCreate = async () => {
    await replaceIngredientCreateRef.value.open();
  };

  const deleteIngredient = async (id) => {
    await store.dispatch('DELETE_EXCLUDED', id);
  };

  const openDialogUpdate = (row) => {
    replaceIngredientUpdateRef.value.open(row);
  };

  return {
    replaceIngredientCreateRef,
    openDialogCreate,
    deleteIngredient,
    replaceIngredientUpdateRef,
    openDialogUpdate,
  };
}
