import { computed, ref } from '@vue/composition-api';
import store from '@/app/app-state';
import { INGREDIENTS_STORE_MODULE_NAME } from '@/app/administration/ingredients/ingredientsStoreModule';
import { Notification } from 'element-ui';

export function useReplaceIngredientUpdate() {
  const isOpen = ref(false);
  const excludedIngredient = ref(null);
  const replaceIngredient = ref(null);
  const ingredientId = ref(null);
  const description = ref(null);

  const fetchIngredients = () => {
    store.dispatch(`${INGREDIENTS_STORE_MODULE_NAME}/fetchIngredients`);
  };

  const open = (row) => {
    isOpen.value = true;
    excludedIngredient.value = row.excludedIngredientId;
    replaceIngredient.value = row.replaceIngredientId;
    ingredientId.value = row.id;
    description.value = row.description;
    fetchIngredients();
  };

  const ingredients = computed(
    () => store.getters[`${INGREDIENTS_STORE_MODULE_NAME}/autocompleteIngredients`]
  );

  const storeReplaceIngredient = async () => {
    try {
      await store.dispatch('UPDATE_EXCLUDED', {
        id: ingredientId.value,
        ingredient_id: excludedIngredient.value,
        replace_ingredient_id: replaceIngredient.value,
        description: description.value,
      });
      Notification.success({
        title: 'Успешно',
        message: 'Предпочтения успешно обновленно',
        duration: 1500,
      });
      isOpen.value = false;
    } catch (error) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка добавления',
        duration: 1500,
      });
    }
  };

  return {
    isOpen,
    open,
    ingredients,
    storeReplaceIngredient,
    excludedIngredient,
    replaceIngredient,
    description,
  };
}
