import { httpClient } from '@/app/shared/services';

export const INGREDIENTS_STORE_MODULE_NAME = 'ingredients';

export const ingredientsStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
  },
  getters: {
    autocompleteIngredients: (state) => state.data.map((p) => ({ value: p.id, label: p.title })),
  },
  mutations: {
    SET_INGREDIENTS_DATA(state, payload) {
      state.data = payload;
    },
    SET_INGREDIENTS_META(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    fetchIngredients({ commit }) {
      return new Promise((res, rej) => {
        httpClient
          .get('/ingredients', { params: { limit: 1000 } })
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_INGREDIENTS_DATA', data);
            commit('SET_INGREDIENTS_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
