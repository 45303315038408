<template>
  <div class="mt-4">
    <el-row :gutter="0" class="mb-4">
      <el-col :span="6">
        <div class="is-flex is-align-items-center">
          <span class="has-text-info mr-2">Фамилия Имя Отчество: </span>
          <el-link
            class="edit-link-primary"
            type="primary"
            icon="el-icon-edit"
            @click="editField('name')"
          />
        </div>
        <span v-if="!fields.name" class="mt-2" @dblclick="editField('name')">
          {{ client.name }}
        </span>
        <div v-else class="is-flex mt-2">
          <el-input v-model="client.name" size="small" autosize />
          <el-link
            type="success"
            class="ml-2 edit-link-success"
            size="mini"
            icon="el-icon-check"
            :underline="false"
            @click="sendUpdateData('name')"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="is-flex is-align-items-center">
          <span class="has-text-info mr-2">Тип клиента: </span>
        </div>
        <el-select
          v-model="client.type_id"
          size="small"
          filterable
          placeholder="Тип клиента"
          @change="sendUpdateData"
        >
          <el-option
            v-for="item in clientTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <div>
          <div class="is-flex is-align-items-center">
            <span class="has-text-info mr-2">Время доставки: </span>
            <el-link
              class="edit-link-primary"
              type="primary"
              icon="el-icon-edit"
              @click="editField('timeDelivery')"
            />
          </div>
          <span v-if="!fields.timeDelivery" class="mt-2" @dblclick="editField('timeDelivery')">
            {{ formattedTimeRange(client.timeDelivery) }}</span
          >
          <div v-else class="is-flex mt-2">
            <el-time-select
              v-model="client.timeDelivery[0]"
              size="small"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="От"
            />
            <span class="mr-2 ml-2">-</span>
            <el-time-select
              v-model="client.timeDelivery[1]"
              size="small"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="До"
            />
            <el-link
              type="success"
              class="ml-2 edit-link-success"
              size="mini"
              icon="el-icon-check"
              :underline="false"
              @click="sendUpdateData('timeDelivery')"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="4" :offset="2">
        <div class="is-flex is-align-items-center">
          <span class="has-text-info mr-2">Баланс: </span>
          <el-button
            class="btn-plus"
            type="primary"
            icon="el-icon-plus"
            circle
            @click="handleAddBalance"
          />
        </div>
        <span class="mt-2">{{ client.balance }} &#8381;</span>
        <span> ~ на {{ client.balance_forecast }} д. </span>
        <div class="is-flex mt-3">
          <el-radio v-model="client.taxSystem" label="Усн" @change="sendUpdateData('taxSystem')">
            Усн
          </el-radio>
          <el-radio v-model="client.taxSystem" label="Патент" @change="sendUpdateData('taxSystem')">
            Патент
          </el-radio>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mb-6">
      <el-col :span="6">
        <div>
          <div class="is-flex is-align-items-center">
            <span class="has-text-info mr-2">Email: </span>
            <el-link
              class="edit-link-primary"
              type="primary"
              icon="el-icon-edit"
              @click="editField('email')"
            />
          </div>
          <span v-if="!fields.email" class="mt-2" @dblclick="editField('email')">{{
            client.email
          }}</span>
          <div v-else class="is-flex mt-2">
            <el-input v-model="client.email" size="small" autosize />
            <el-link
              type="success"
              class="ml-2 edit-link-success"
              size="mini"
              icon="el-icon-check"
              :underline="false"
              @click="sendUpdateData('email')"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <div class="is-flex is-align-items-center">
            <span class="has-text-info mr-2">Телефон: </span>
            <el-link
              class="edit-link-primary"
              type="primary"
              icon="el-icon-edit"
              @click="editField('phone')"
            />
          </div>
          <span v-if="!fields.phone" class="mt-2" @dblclick="editField('phone')">{{
            client.phone
          }}</span>
          <div v-else class="is-flex mt-2">
            <masked-input
              v-model="client.phone"
              mask="\+# ### ###-####"
              placeholder="8 929 003 12 34"
              class="el-input__inner"
            />
            <el-link
              type="success"
              class="ml-2 edit-link-success"
              size="mini"
              icon="el-icon-check"
              :underline="false"
              @click="sendUpdateData('phone')"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <div class="is-flex is-align-items-center">
            <span class="has-text-info mr-2">Дата создания: </span>
            <el-link
              class="edit-link-primary"
              type="primary"
              icon="el-icon-edit"
              @click="editField('created_at')"
            />
          </div>
          <span v-if="!fields.created_at" class="mt-2" @dblclick="editField('created_at')">{{
            client.created_at
          }}</span>
          <div v-else class="is-flex mt-2">
            <el-date-picker
              v-model="client.created_at"
              type="date"
              size="small"
              :picker-options="{ firstDayOfWeek: 1 }"
              placeholder="Дата создания"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
            />
            <el-link
              type="success"
              class="ml-2 edit-link-success"
              size="mini"
              icon="el-icon-check"
              :underline="false"
              @click="sendUpdateData('created_at')"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <replace-ingredient :excluded="client.excluded" />
    <el-row :gutter="20">
      <el-col :span="12" class="mb-3">
        <span>Адреса доставки</span>
      </el-col>
      <el-col :span="12" class="is-flex is-justify-content-flex-end mb-3">
        <el-button type="primary" icon="el-icon-plus" @click="modals.address.show = true">
          Добавить адрес доставки
        </el-button>
      </el-col>
      <el-col :span="24" class="mb-6">
        <el-table :data="client.address" :row-class-name="tableRowClassName">
          <el-table-column prop="id" label="#" />
          <el-table-column prop="region" label="Область" />
          <el-table-column prop="city" label="Город" />
          <el-table-column prop="districtName" label="Район" />
          <el-table-column prop="street" label="Улица" />
          <el-table-column prop="house" label="Дом" />
          <el-table-column prop="entrance" label="Подъезд" />
          <el-table-column prop="apartment" label="Квартира" />
          <el-table-column prop="comment" label="Комментарий" />
          <el-table-column label="Предпочтительный">
            <template slot-scope="{ row }">
              <el-checkbox v-model="row.isMain" @change="updateAddress(row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column align="right" width="100">
            <template slot-scope="{ row }">
              <el-button icon="el-icon-edit" type="primary" size="mini" @click="editAddress(row)" />
              <el-button
                icon="el-icon-delete"
                type="danger"
                size="mini"
                @click="handleDeleteAddress(row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <!--        <general-table-->
        <!--          :data="client.address"-->
        <!--          :loading="loading"-->
        <!--          :fields="fieldsAddress"-->
        <!--          :rules="addressRules"-->
        <!--          :pagination="false"-->
        <!--          search-field="address"-->
        <!--          @delete="handleDeleteAddress"-->
        <!--          @edit="editAddress"-->
        <!--        />-->
      </el-col>
    </el-row>
    <el-dialog
      :title="titleIngredient"
      :visible.sync="showAddIngredient"
      width="30%"
      :before-close="handleCloseIngredients"
    >
      <autocomplete action="INGREDIENTS" :values.sync="ingredient.title" :select="handleSelect" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddIngredient = false">Закрыть</el-button>
        <el-button type="primary" :loading="loading" @click="sendDataIngredients">
          {{ client.id ? 'Сохранить' : 'Добавить' }}
        </el-button>
      </span>
    </el-dialog>
    <address-modal
      :title="modals.address.title"
      :show.sync="modals.address.show"
      :address="address"
    />
    <el-dialog :title="modals.balance.title" :visible.sync="modals.balance.show" width="30%">
      <el-form :model="client" label-position="top">
        <el-form-item label="Сумма">
          <el-input v-model="balance" type="number" autocomplete="off" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modals.balance.show = false">Закрыть</el-button>
        <el-button type="primary" :loading="loading" @click="sendUpdateData('balance')">
          {{ client.id ? 'Сохранить' : 'Добавить' }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GeneralTable from '@/components/common/GeneralTable.vue';
import store from '@/app/app-state';
import { computed } from '@vue/composition-api';
import {
  CLIENT_TYPE_STORE_MODULE_NAME,
  clientTypesStoreModule,
} from '@/app/settings/client-types/clientTypesStoreModule';
import MaskedInput from 'vue-masked-input';
import ReplaceIngredient from '@/views/clients-base/client/components/replace-ingredient/replace-ingredient';
import { mapGetters } from 'vuex';
import AddressModal from './address/AddressModal.vue';
import Autocomplete from '../../../components/common/Autocomplete.vue';

export default {
  name: 'Info',
  components: { ReplaceIngredient, GeneralTable, Autocomplete, AddressModal, MaskedInput },
  data: () => ({
    state: '',
    ingredients: [],
    ingredient: {
      id: '',
      title: '',
    },
    ingredientsDecoder: [],
    showAddIngredient: false,
    titleIngredient: 'Добавить ингредиент',
    renderKey: 0,
    loading: false,
    fields: {
      name: false,
      email: false,
      phone: false,
      timeDelivery: false,
      created_at: false,
    },
    balance: null,
    modals: {
      balance: {
        show: false,
        title: 'Пополнить Баланс',
      },
      ingredients: false,
      address: {
        show: false,
        title: 'Добавить адрес доставки',
      },
    },
    showAddBalance: false,
    fieldsIngredients: [
      { label: '#', value: 'id' },
      { label: 'Название', value: 'ingredient.title' },
    ],
    fieldsAddress: [
      { label: '#', value: 'id' },
      { label: 'Область', value: 'region' },
      { label: 'Город', value: 'city' },
      { label: 'Район', value: 'districtName' },
      { label: 'Улица', value: 'street' },
      { label: 'Дом', value: 'house' },
      { label: 'Подъезд', value: 'entrance' },
      { label: 'Квартира', value: 'apartment' },
      { label: 'Комментарий', value: 'comment' },
    ],
    addressRules: [
      {
        value: 'edit',
        type: 'primary',
        icon: 'el-icon-edit',
      },
      {
        value: 'delete',
        type: 'danger',
        icon: 'el-icon-delete',
      },
    ],
    rules: [
      {
        value: 'delete',
        type: 'danger',
        icon: 'el-icon-delete',
      },
    ],
    dataAddress: {
      id: '',
      address: '',
    },
    address: {
      region: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
      entrance: '',
    },
    client: {},
  }),
  computed: {
    ...mapGetters({ clients: 'CLIENT', messages: 'MESSAGES' }),
  },
  watch: {
    clients(value) {
      this.client = {
        ...value,
        timeDelivery: JSON.parse(
          Array.isArray(value.timeDelivery)
            ? JSON.stringify(value.timeDelivery)
            : value.timeDelivery
        ),
      };
    },
  },
  async mounted() {
    await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
    await this.$store.dispatch('FETCH_INGREDIENTS');
    this.ingredientsDecoder = this.$store.getters.INGREDIENTS;
    this.loading = false;
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.isMain) {
        return 'success-row';
      }
      return '';
    },
    formattedDate(date) {
      if (date) {
        const d = new Date(date);
        return new Intl.DateTimeFormat('ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(d);
      }
      return null;
    },
    editAddress(item) {
      this.modals.address.show = true;
      this.address = item;
    },
    async reloadClient() {
      await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
      this.client = this.$store.getters.CLIENT;
    },
    handleAddIngredient() {
      this.showAddIngredient = true;
    },
    handleDeleteIngredient(ingredient) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_EXCLUDED', ingredient.id);
            this.$notify({
              title: 'Успешно',
              message: 'Предпочтения успешно удалены',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
          await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
          this.client = this.$store.getters.CLIENT;
          this.loading = false;
        })
        .catch(() => {});
    },
    async handleCloseIngredients() {
      await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
      this.client = this.$store.getters.CLIENT;
      this.showAddIngredient = false;
    },
    async sendDataIngredients() {
      try {
        await this.$store.dispatch('STORE_EXCLUDED', {
          ingredient_id: this.ingredient.id,
        });
        this.$notify({
          title: 'Успешно',
          message: 'Предпочтения успешно добавленно',
          duration: 1500,
          type: 'success',
        });
      } catch (error) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка добавления',
          duration: 1500,
        });
      }
      await this.handleCloseIngredients();
      this.loading = false;
    },
    querySearch(queryString, cb) {
      const ingredients = this.ingredientsDecoder;
      const results = queryString
        ? ingredients.filter(this.createFilter(queryString))
        : ingredients;
      cb(results);
    },
    createFilter(queryString) {
      return (item) => item.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleSelect(item) {
      this.ingredient = item;
    },
    handleAddAddress() {
      this.showAddAddress = true;
    },
    handleCloseAddress() {
      this.reloadClient();
      this.modals.address.show = false;
    },
    handleDeleteAddress(address) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_ADDRESS', address.id);
            this.$notify({
              title: 'Успешно',
              message: 'Адрес успешно удален',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка добавления',
              duration: 1500,
            });
          }
          this.reloadClient();
          this.loading = false;
        })
        .catch(() => {});
    },
    async updateAddress(row) {
      await this.$store.dispatch('UPDATE_ADDRESS', row);
    },
    async sendDataAddress() {
      this.loading = true;
      try {
        await this.$store.dispatch('STORE_ADDRESS', this.dataAddress);
        this.$notify({
          title: 'Успешно',
          message: 'Адрес успешно добавлен',
          duration: 1500,
          type: 'success',
        });
      } catch (error) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка добавления',
          duration: 1500,
        });
      }
      await this.handleCloseAddress();
      this.loading = false;
    },
    async sendUpdateData(field) {
      if (field === 'balance') {
        const client = await this.$store.dispatch('UPDATE_BALANCE_CLIENT', {
          id: this.client.id,
          balance: this.balance,
        });
        this.client = client;
        await this.reloadClient();
      } else {
        await this.$store.dispatch('UPDATE_CLIENT', this.client);
      }
      this.editField(field);
      if (this.modals[field]) this.modals[field].show = false;
    },
    editField(field) {
      this.fields[field] = !this.fields[field];
    },
    handleAddBalance() {
      this.modals.balance.show = true;
    },
    async sendDataBalance() {
      if (this.client.id) {
        await this.$store.dispatch('UPDATE_CLIENT', this.client);
        await this.handleCloseBalance();
        this.loading = false;
        return true;
      }
      this.loading = true;
      await this.$store.dispatch('STORE_CLIENT', this.client);
      await this.handleCloseBalance();
      this.loading = false;
      return true;
    },
    async handleCloseBalance() {
      await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
      this.client = this.$store.getters.CLIENT;
      this.showAddBalance = false;
    },
    formattedTimeRange(date) {
      if (date) {
        if (date[0] && date[1]) {
          const to = date[0];
          const from = date[1];
          return `${to}-${from}`;
        }
      }
      return '-';
    },
  },
  setup() {
    if (!store.hasModule(CLIENT_TYPE_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_TYPE_STORE_MODULE_NAME, clientTypesStoreModule);
    }
    const fetchClientTypes = (params) => {
      store.dispatch(`${CLIENT_TYPE_STORE_MODULE_NAME}/fetchClientTypes`, params);
    };
    fetchClientTypes();
    const clientTypes = computed(
      () => store.getters[`${CLIENT_TYPE_STORE_MODULE_NAME}/autocompleteClientTypes`]
    );
    return { clientTypes };
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.btn-plus {
  padding: 4px;
}

.edit-link-primary {
  font-size: 1.1rem;
}
.edit-link-success {
  font-size: 1.5rem;
}
.el-table .success-row {
  background: #f0f9eb !important;
}
</style>
